import React from 'react';
import WayFadeUp from './transitions/WayFadeUp';
import { PrismicImage } from './common/prismicUtils';
const TwoUpImage = ({ smallImg, largeImg }) => (
  <WayFadeUp>
    <div className="pl4 pr4 pl5-l pr5-l mt5 mb5 mt6-l mb6-l flex justify-between items-center mw9 center">
      <div className="w-40 w-30-l">
        <PrismicImage {...smallImg.fluid} />
      </div>
      <div className="w-50 w-50-l">
        <PrismicImage {...largeImg.fluid} />
      </div>
    </div>
  </WayFadeUp>
);

export default TwoUpImage;
