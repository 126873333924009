import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/PageHeader';
import TwoUpImage from '../components/TwoUpImage';
import ContactDetails from '../components/ContactDetails';

const ContactPage = ({ data: { prismicContactPage } }) => {
  const { data } = prismicContactPage;
  return (
    <Layout headerColor="dark">
      <SEO title={data.page_title.text} />
      <div className="page-spacer db" />
      <PageHeader title={data.page_title} subTitle={data.sub_title} />
      <TwoUpImage smallImg={data.small_image} largeImg={data.large_image} />
      <ContactDetails details={data.contact_details} />
    </Layout>
  );
};

export default withPreview(ContactPage);

export const pageQuery = graphql`
  query {
    prismicContactPage {
      data {
        page_title {
          text
        }
        sub_title {
          text
        }
        small_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        large_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        contact_details {
          title {
            text
          }
          details {
            html
          }
        }
      }
    }
  }
`;
