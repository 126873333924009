import React from 'react';
import WayFadeUp from './transitions/WayFadeUp';

const ContactDetails = ({ details }) => {
  return (
    <div className="pl4 pr4 pl5-l pr5-l mt5 mb5 mt6-l mb6-l mw9 center">
      {details.map(item => (
        <div className="w-100 mt5 mb5 mt6-l mb6-l">
          <WayFadeUp>
            <h4 className="gold f3 mb0 ml0 mr0">{item.title.text}</h4>
            <div
              className="lh-copy f3 f1-l measure tiempos contact-details"
              dangerouslySetInnerHTML={{ __html: item.details.html }}
            />
          </WayFadeUp>
        </div>
      ))}
    </div>
  );
};

export default ContactDetails;
